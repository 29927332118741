import { ChangeEvent, ChangeEventHandler, ReactElement, useCallback, useMemo } from "react";
import { Box, InputLabel, MenuItem, SxProps, TextField, Theme, Typography } from "@mui/material";
import groupBy from "lodash/groupBy";
import uniqueId from "lodash/uniqueId";

import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

export interface InvestigateDropdownOption {
  title: string;
  value?: string;
  group?: string | null;
}

interface Props {
  defaultValue?: string;
  disabled?: boolean;
  grouped?: boolean;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  options: InvestigateDropdownOption[];
  placeholder?: string;
  preventGrowth?: boolean;
  sx?: SxProps<Theme>;
  title: string | ReactElement;
  type?: "Chart" | "Map";
  value?: string;
  valueIsId?: boolean;
  valueItemIsSelected?: (value?: string, selectedValue?: string) => boolean;
  disableRule?: (value?: string) => boolean;
}

export const InvestigateDropdown: React.FC<Props> = ({
  defaultValue,
  disabled = false,
  disableRule,
  grouped = false,
  name,
  onChange,
  options,
  placeholder = "Select Option",
  preventGrowth,
  sx,
  title,
  type = "Chart",
  value,
  valueIsId = false,
  valueItemIsSelected
}) => {
  const _options = useMemo(() => {
    if (!grouped || options.map((option) => typeof option.group === "string").length > 0)
      return options;
    const groupedOptions: InvestigateDropdownOption[][] = [];
    Object.entries(groupBy(options, "group")).forEach(([groupName, groupOptions]) => {
      groupedOptions.push([{ title: groupName }, ...groupOptions]);
    });
    return groupedOptions.flat();
  }, [options, grouped]);

  const selectedValue = useMemo(() => {
    return value === "All" ? undefined : value;
  }, [value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      sendGaUserInteractionEvent({
        category: `${type}s`,
        action: "Investigate Dropdown Change",
        label: event.target.value
      });
      onChange?.(event);
    },
    [onChange, type]
  );

  const textFieldId = `select-data-set-${uniqueId()}`;
  const textFieldLabelId = `${textFieldId}-label`;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: preventGrowth ? "0" : "1",
        maxWidth: { xs: "100%", lg: "none" },
        flex: { xs: 1, lg: 0 },
        ...sx
      }}
    >
      <InputLabel id={textFieldLabelId}>{title}</InputLabel>
      <TextField
        name={name}
        select
        value={selectedValue}
        defaultValue={defaultValue}
        onChange={handleChange}
        disabled={disabled ?? false}
        SelectProps={{
          id: textFieldId,
          labelId: textFieldLabelId,
          displayEmpty: true,
          placeholder,
          value: selectedValue === undefined ? "" : selectedValue,
          role: "menu",
          renderValue: (value) => {
            let _value = value as string;
            if (selectedValue === undefined || !value)
              return <Typography color="action.disabled">{placeholder}</Typography>;
            if (valueIsId) {
              _value = options.find((o) => o.value === value)?.title ?? "";
            } else {
              _value = value as string;
              _value.charAt(0).toUpperCase();
              _value.slice(1);
            }
            return <>{_value}</>;
          }
        }}
        sx={({ palette }) => ({
          fontWeight: "bold",
          backgroundColor: palette.common.white,
          width: { xs: "100%", lg: "auto" },
          "& .MuiSelect-select": {
            color: value === defaultValue ? undefined : palette.dropdowns.main
          },
          "& [role='heading']": {
            marginTop: 2
          }
        })}
      >
        {_options.map(({ title, value }) => {
          const isSelected = valueItemIsSelected && valueItemIsSelected(value, selectedValue);
          return (
            <MenuItem
              key={title}
              value={value}
              selected={selectedValue !== undefined && value === selectedValue}
              disabled={disableRule ? disableRule?.(value) : value === undefined}
              sx={({ palette }) =>
                value === undefined
                  ? {
                      // Mimic AutoComplete group title style
                      opacity: "1 !important",
                      mt: 1,
                      pl: 1.75,
                      color: palette.component.textColor,
                      fontWeight: 600,
                      fontSize: "0.875rem",
                      position: "sticky",
                      zIndex: "1",
                      backgroundColor: "rgb(255, 255, 255)",
                      textTransform: "uppercase"
                    }
                  : {
                      color: isSelected
                        ? `${palette.component.links ?? "#0060F0"} !important`
                        : palette.component.textColor,
                      backgroundColor: isSelected
                        ? "rgba(2, 136, 209, 0.035) !important"
                        : undefined
                    }
              }
            >
              <Box
                component="span"
                sx={{
                  display: "block",
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              >
                {title}
              </Box>
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
};
